import {
  HorizontalAlignment,
  VerticalAlignment,
} from '@thg-commerce/enterprise-core'
import {
  margin,
  padding,
  spacing,
  styled,
  Text,
} from '@thg-commerce/enterprise-theme'
import { Button, SafeHtml } from '@thg-commerce/gravity-elements'
import { Grid, GridItem } from '@thg-commerce/gravity-system'

import { CardType, TextColor } from './types'

interface TextProps {
  horizontalAlignment?: HorizontalAlignment
  textColor?: TextColor
  marginBottom?: number
}

export const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.site.siteWidth};
  margin: auto;
  padding: ${spacing(1)} ${spacing(3)};
  margin-bottom: ${spacing(3)};
`

export const CardContainer = styled(Grid)<{
  cardType: CardType
  noGridGap?: boolean
}>`
  width: 100%;
  ${(props) =>
    props.cardType === 'TextUnder' &&
    `grid: none; display:flex; flex-direction: column;`}
  ${(props) => props.noGridGap && `grid-gap: 0px;`}
  height: ${(props) =>
    props.theme.widget.imageCardSet.card.height
      ? props.theme.widget.imageCardSet.card.height
      : 'auto'};
`

export const ContainerLink = styled.a<{ withFocus?: boolean }>`
  text-decoration: none;
  display: block;
  height: ${(props) =>
    props.theme.widget.imageCardSet.height
      ? props.theme.widget.imageCardSet.height
      : 'auto'};

  &:focus-visible {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};

    h2 {
      border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
      margin: -${spacing(0.25)} -${spacing(0.25)} ${spacing(0.75)};
    }
  }
`

export const MainTitle = styled.h2<TextProps>`
  ${(props) =>
    Text(
      props.theme.widget.imageCardSet.title.font.entry,
      props.theme.widget.imageCardSet.title.font.style,
      props.theme.widget.imageCardSet.title.font.hasScaledText,
    )};
  text-align: ${(props) => props.horizontalAlignment || 'center'};
  ${(props) => margin(props.theme.widget.imageCardSet.title.margin)}
`

export const MainSubtitle = styled.h2<TextProps>`
  ${(props) =>
    Text(
      props.theme.widget.imageCardSet.subtitle.font.entry,
      props.theme.widget.imageCardSet.subtitle.font.style,
    )};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : spacing(3)};
  text-align: ${(props) => props.horizontalAlignment || 'center'};
`

export const CarouselContainer = styled.div`
  height: max-content;
`

export const CardImageGridItem = styled(GridItem)`
  margin: 0;
`

export const CardTextGridItem = styled(GridItem)<{
  verticalAlignment?: VerticalAlignment
  horizontalAlignment?: HorizontalAlignment
  cardType: CardType
  displayBorder: boolean
}>`
  display: flex;
  flex-direction: column;
  margin: 0;
  ${(props) =>
    props.theme.widget.imageCardSet.card.title.padding
      ? padding(props.theme.widget.imageCardSet.card.title.padding)
      : `padding: ${spacing(3)} ${spacing(2)};`}
  z-index: 1;
  text-align: ${(props) => props.horizontalAlignment};
  justify-content: ${(props) =>
    props.verticalAlignment === 'middle'
      ? 'center'
      : props.verticalAlignment === 'bottom'
      ? 'end'
      : props.theme.widget.imageCardSet.card.alignment?.column.justifyContent ??
        'start'};
  ${(props) => props.cardType === 'TextUnder' && `height: 100%;`}
  ${(props) =>
    props.cardType === 'TextUnder' &&
    props.displayBorder &&
    `border: solid 1px ${
      props.theme.widget.imageCardSet.card.borderColour ??
      props.theme.colors.palette.greys.lighter
    };`}
`

export const CardTitle = styled.h2<TextProps>`
  ${(props) =>
    Text(
      props.theme.widget.imageCardSet.card.title.font.entry,
      props.theme.widget.imageCardSet.card.title.font.style,
    )};
  text-transform: ${(props) =>
    props.theme.widget.imageCardSet.card.title.font.transform || 'none'};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : spacing(1)};
  color: ${(props) =>
    props.textColor === 'light'
      ? props.theme.colors.palette.greys.white
      : props.theme.colors.palette.greys.darker};
`

export const CardSubtitle = styled(SafeHtml)<TextProps>`
  ${(props) =>
    Text(
      props.theme.widget.imageCardSet.card.subtitle.font.entry,
      props.theme.widget.imageCardSet.card.subtitle.font.style,
    )};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : spacing(2)};
  color: ${(props) =>
    props.textColor === 'light'
      ? props.theme.colors.palette.greys.white
      : props.theme.colors.palette.greys.darker};
`

export const CardCTA = styled(Button)<{
  horizontalAlignment?: HorizontalAlignment
}>`
  margin-top: ${spacing(2)};
  align-self: ${(props) =>
    props.horizontalAlignment === 'left'
      ? 'start'
      : props.horizontalAlignment === 'right'
      ? 'end'
      : 'center'};
`
