import * as React from 'react'

import {
  HorizontalAlignment,
  useTheme,
  VerticalAlignment,
} from '@thg-commerce/enterprise-core'
import {
  GlobalCardScrollerCardPicture,
  GlobalImageCardPicture,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/GlobalImageCardPicture'
import {
  GlobalCardScrollerSet,
  GlobalImageCardSet,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  CardType,
  ImageCardCarousel,
  ImageCardProps,
  TextColor,
} from '@thg-commerce/enterprise-widget-image-card-set'
import { ButtonEmphases } from '@thg-commerce/gravity-elements'

import { BaseWidgetProps } from '../types'

export interface ImageCardSetWithChildren
  extends Omit<GlobalImageCardSet, 'banners'> {
  banners: GlobalImageCardPicture[]
}

export interface ImageCardScrollerWithChildren
  extends Omit<GlobalCardScrollerSet, 'banners'> {
  banners: GlobalCardScrollerCardPicture[]
}

export type ImageCardSetRendererProps =
  | (BaseWidgetProps & ImageCardSetWithChildren)
  | (BaseWidgetProps & ImageCardScrollerWithChildren)

const createImageCard = (
  item: GlobalImageCardPicture | GlobalCardScrollerCardPicture,
  cardType: CardType,
  emphasis?: ButtonEmphases,
  horizontalAlignment?: HorizontalAlignment,
): ImageCardProps => {
  return {
    cardType,
    verticalAlignment:
      (item.verticalPositionOfText as VerticalAlignment) || 'top',
    link: item.url || undefined,
    picture: { ...item.picture, lazy: true, style: { width: '100%' } },
    contentProps: {
      ...(item.title && {
        title: {
          text: item.title,
          color: item.colorOfText as TextColor,
        },
      }),
      ...(item.subtitle && {
        subtitle: {
          text: item.subtitle || '',
          color: item.colorOfText as TextColor,
        },
      }),
      horizontalAlignment: horizontalAlignment || 'center',
      cta: {
        text: item.buttonText || undefined,
        emphasis: emphasis ?? 'medium',
      },
    },
  }
}

export const ImageCardSetRenderer = (props: ImageCardSetRendererProps) => {
  const theme = useTheme()
  const cardType: CardType =
    props.cardStyle === 'image-text-overlay' ? 'TextOver' : 'TextUnder'

  const imageCards = props.banners.map(
    (item: GlobalImageCardPicture | GlobalCardScrollerCardPicture) => ({
      ...createImageCard(
        item,
        cardType,
        theme.widget.imageCardSet.card.cta.emphasis,
        theme.widget.imageCardSet.card.horizontalAlignment,
      ),
      displayBorder:
        'noBorder' in item && item.noBorder === true ? false : true,
    }),
  )
  return (
    <ImageCardCarousel
      imageCards={imageCards}
      cardType={cardType}
      title={props.title ? { text: props.title } : undefined}
      horizontalAlignment="center"
    />
  )
}
